<template>
    <div :class="CSSClasses" class="person-list-item">
        <div class="person-titles">
            <Anchor v-if="showInternalProfile" :url="avatarProps?.path">
                <Typography class="title" as="p" variant="body-display-small">
                    {{ personNameOverride || getPersonFullName(person) }}
                </Typography>
            </Anchor>
            <Typography v-else class="title" as="p" variant="body-display-small">
                {{ personNameOverride || getPersonFullName(person) }}
            </Typography>

            <Typography v-if="person?.personTitle" class="person-title" as="p" variant="body-display-micro-italic">
                {{ person?.personTitle }}
            </Typography>
            <RichTextElements v-else :elements="person?.dek" bodyStyle="body-display-micro-italic" />
        </div>

        <Avatar class="avatar" v-if="person?.avatar" v-bind="avatarProps" />
    </div>
</template>

<script setup>
const props = defineProps({
    isExpert: {
        type: Boolean,
        default: false,
    },
    person: {
        type: Object,
    },
    personNameOverride: {
        type: String,
    },
    pageLanguage: {
        type: String,
        default: 'en',
    },
});

const CSSClasses = computed(() => [props.isExpert && 'expert']);

const avatarProps = computed(() => ({
    path: props.person?.path + '?lang=' + props.pageLanguage,
    image: { ...props.person?.avatar },
    size: 'default',
}));

const showInternalProfile = computed(() => {
    if (props.person?.type === 'internal') {
        return true;
    } else {
        return props.person?.hasProfile;
    }
});
</script>

<style lang="scss" scoped>
.person-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    .author:not(:first-child) & {
        margin-top: 2rem;
    }
    .speaker:not(:first-child) & {
        margin-top: 2rem;
    }
}

.title {
    color: palette(text);
    a & {
        color: rgb(var(--color-rgb-blue-default));
    }
}

.expert {
    .title {
        color: palette(text-blue);
    }
}

.person-title,
.person-titles .body-style-body-display-micro-italic {
    opacity: 0.6;
}

.avatar {
    flex: 0 0 auto;
}
</style>
